import { Injectable } from '@angular/core'
import { ToastrService as ToastrServiceOriginal, IndividualConfig, ActiveToast } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ToastrService {


    config: Partial<IndividualConfig> = {
        closeButton: true,
        progressBar: true,
        progressAnimation: 'decreasing',
        positionClass: 'toast-top-center'
    };

    constructor(
        private toastr: ToastrServiceOriginal
    ) { }

    /**
     * Exibe uma mensagem informativa para o usuário
     * @param message Mensagem a ser exibida ao usuário
     * @param title Titulo da mensagem exibida ao usuário
     */
    public info(message: string, title: string = 'Informação', config: Partial<IndividualConfig> = this.config): ActiveToast<any> {
        return this.toastr.info(message, title, config);
    }

    /**
     * Exibe uma mensagem de erro ao usuário
     * @param message Mensagem a ser exibida ao usuário
     * @param title Titulo da mensagem exibida ao usuário
     */
    public error(message: string, title: string = 'Erro', config: Partial<IndividualConfig> = this.config) {
        return this.toastr.error(message, title, config);
    }

    /**
     * Exibe uma mensagem de aviso ao usuário
     * @param message Mensagem a ser exibida ao usuário
     * @param title Titulo da mensagem exibida ao usuário
     */
    public warning(message: string, title: string = 'Atenção', config: Partial<IndividualConfig> = this.config) {
        return this.toastr.warning(message, title, config);
    }

    /**
     * Exibe uma mensagem de sucesso ao usuário
     * @param message Mensagem a ser exibida ao usuário
     * @param title Titulo da mensagem exibida ao usuário
     */
    public success(message: string, title: string = 'Sucesso', config: Partial<IndividualConfig> = this.config) {
        return this.toastr.success(message, title, config);
    }

}