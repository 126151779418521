import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';
import { ToastrService } from '../services/toastr.service';

@Injectable()
export class Interceptor implements HttpInterceptor {

    constructor(private loading: LoadingService, private toastrService: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!request.params.get('notLoading')) {
            this.loading.loading = true;
        }

        let gdtoken = !request.params.get('gdtoken') ? localStorage.getItem("gdtoken") : request.params.get('gdtoken');

        const updateRequest = request.clone({
            url: request.url.replace(/\/$/, ''),
            headers: this.getHeaders(gdtoken)
        });


        return next.handle(updateRequest).pipe(
            catchError((response: HttpErrorResponse) => {
                this.errors(response);
                return throwError(response);
            })
            , finalize(() => {
                this.loading.loading = false;
            }));
    }

    getHeaders(gdtoken: any): HttpHeaders {

        const headers = new HttpHeaders({
            gdtoken: gdtoken ?? '',
            'Accept-Language': 'pt-BR'
        });

        return headers;

    }

    errors(response: HttpErrorResponse): void {
        if (response.status >= 400 && response.status < 500) {
            const { message } = response.error;
            this.toastrService.info(message);
        } else if (response.status >= 500) {
            console.log("erro");
            this.toastrService.warning("Ocorreu um erro interno. Tente novamente mais tarde");
        }
    }

}
