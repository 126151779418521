import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Search } from './Search';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @Input() search: Search;
  @Output() searchChange = new EventEmitter<Search>();
  @ViewChild(MatPaginator) matPaginator: MatPaginator;

  constructor() { }

  ngOnInit(): void {
    this.search.goToFirstPage.subscribe(() => this.matPaginator.firstPage());
  }

  paginatorChanged(e: any) {
    this.search.pageSize = e.pageSize;
    this.search.page = e.pageIndex + 1;
    this.searchChange.emit(this.search);
  }

  goToFirstPage() {
    this.matPaginator.pageIndex = 0;
  }

}
