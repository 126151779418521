import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";

@Injectable()
export class MatPaginatorIntlCustom extends MatPaginatorIntl {
  itemsPerPageLabel: string = 'Itens por página';
  nextPageLabel: string = 'Próxima página';
  previousPageLabel: string = 'Página anterior';
  firstPageLabel: string = 'Primeira página';
  lastPageLabel: string = 'Última página';

  getRangeLabel: (page: number, pageSize: number, length: number) => string = ((page, pageSize, length) => {
    if (length === 0 || pageSize === 0) {
      return '0 de ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
  });
}